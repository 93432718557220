import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";

import { AgentImage } from "@/game-val/CommonComponent.jsx";
import {
  CURRENT_ACT_EMPTY_TABLE_MESSAGES,
  useGetAgentsData,
} from "@/game-val/stats-utils.mjs";
import GameGuideTable from "@/marketing/GameGuideTable.jsx";
import GameGuideTileLg from "@/marketing/GameGuideTileLg.jsx";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useTransientRoute } from "@/util/router-hooks.mjs";

const COLS = [
  {
    label: ["val:agent", "Agent"],
    dataKey: "agent",
    width: 40,
  },
  {
    label: ["val:winRate", "Win %"],
    dataKey: "winRate",
    width: 20,
  },
  {
    label: ["val:postmatch.kd", "KD"],
    dataKey: "kd",
    width: 20,
  },
  {
    label: ["val:avgScore", "Avg. Score"],
    dataKey: "avgScore",
    width: 20,
  },
];

export default function HomeAgentsStatsTile() {
  const { t } = useTranslation();
  const { searchParams } = useTransientRoute();
  const data = useGetAgentsData(searchParams);

  return (
    <GameGuideTileLg
      title={["common:navigation.statistics", "Statistics"]}
      description={[
        "home:guides.val.statistics.description",
        "Discover which Agents perform best with win rates, pick rates, average scores, and other helpful statistics.",
      ]}
      buttonText={["common:navigation.viewStatistics", "View Statistics"]}
      href={"/valorant/stats/agents"}
    >
      {!data?.length ? (
        <div className="flex column gap-1 align-center h-full justify-center">
          <p className="type-title--bold">
            {t(...CURRENT_ACT_EMPTY_TABLE_MESSAGES.title)}
          </p>
          <p className="type-caption shade1">
            {t(...CURRENT_ACT_EMPTY_TABLE_MESSAGES.description)}
          </p>
        </div>
      ) : (
        <GameGuideTable className={Table()}>
          <GameGuideTable.Header>
            <div className="agent-meta">{t(COLS[0].label)}</div>
            <div className="stat">{t(COLS[1].label)}</div>
            <div className="stat">{t(COLS[2].label)}</div>
            <div className="stat">{t(COLS[3].label)}</div>
          </GameGuideTable.Header>
          <GameGuideTable.Body>
            {(data || []).map((row, i) => (
              <GameGuideTable.Row key={i} link={`/valorant/agents/${row.key}`}>
                <div className={`agent-meta type-subtitle--bold`}>
                  <AgentImage
                    src={row.agent.images.matchtile.url}
                    width="36"
                    height="36"
                    className="agent-img"
                  />
                  {row.name}
                </div>
                <div className="stat type-body2-form--bold">
                  {(row.winRate / 100).toLocaleString(getLocale(), {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                    style: "percent",
                  })}
                </div>
                <span className="stat type-body2-form--bold">{row.kd}</span>
                <span className="stat type-body2-form--bold">
                  {row.avgScore}
                </span>
              </GameGuideTable.Row>
            ))}
          </GameGuideTable.Body>
        </GameGuideTable>
      )}
    </GameGuideTileLg>
  );
}

const Table = () => css`
  .agent-meta {
    flex: 1;
    display: flex;
    align-items: center;
    gap: var(--sp-3);
    color: var(--shade0);
  }
  .agent-img {
    width: var(--sp-9);
    height: auto;
    aspect-ratio: 8 / 9;
  }
  .agent-name {
    max-width: 11ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .stat {
    display: flex;
    justify-content: center;
    width: 17%;
    white-space: nowrap;
  }
  .tier svg {
    width: var(--sp-6);
    height: var(--sp-6);
  }
`;
